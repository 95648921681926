<template>
  <div>
    <div class="flex justify-between">
      <a-button @click="$router.back()" class="mr-2">
        Regresar
      </a-button>
<!--      <a-button target="_blank" :href="`${URL}/api/sections/${$route.params.section_id}/courses/${$route.params.course_id}/lessons-report?token=${token}`">-->
<!--        Generar PDF-->
<!--      </a-button>-->
    </div>

    <a-card class="mt-2 w-full h-full" v-if="loading" />
    <a-card v-else class="mt-2">
      <template #header>
        <div class="grid grid-cols-12 justify-between mb-3 mt-2 items-center">
          <h3 class="col-span-6">Clase: <span class="text-lg font-bold">{{ lesson.subject }}</span></h3>
          <h3 class="col-span-3 text-right">Porcentaje de evaluación: <span class="text-lg font-bold">{{ lesson.evaluation_weighting_prc }}%</span></h3>
          <div class="col-span-3">
            <h3 class="text-right">Fecha: <span class="text-lg font-bold">{{ lesson.date }}</span></h3>
            <h3 class="text-right">Fecha de registro: <span class="text-lg font-bold">{{ lesson.registered_at | date }}</span></h3>
          </div>
        </div>
      </template>

      <div class="-my-2 py-2 mt-3 overflow-y-auto">
        <div class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg overflow-y-auto bg-white">
          <table class="min-w-full overflow-y-auto">
            <thead>
            <tr>
              <th class="text-left p-2">Alumno</th>
              <th class="text-center p-2">Nota</th>
<!--              <th class="text-center p-2">Equivalencia en %</th>-->
              <th class="text-center p-2">Nota acumulada</th>
<!--              <th class="text-left p-2">Fecha</th>-->
            </tr>
            </thead>
            <tbody>
            <tr v-for="(student, index) in lesson.students " :key="student.id" :class="index % 2 ? 'bg-gray-200' : 'bg-white'">
              <td class="text-left p-2">
                {{ student.first_name }} {{ student.last_name }}
              </td>
              <td class="text-center p-2">{{ student.pivot.note }}</td>
<!--              <td class="text-center p-2">{{ getPercentNote(student.pivot.note) + '%' }}</td>-->
              <td class="text-center p-2">{{ getGeneralNote(student.pivot.note) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </a-card>
  </div>
</template>


<script>
import { URL } from '@/constants';
import { mapActions, mapState } from 'vuex';
const token = localStorage.getItem(process.env.VUE_APP_USER_TOKEN_NAME)
export default {
  name: 'detail',
  computed: mapState({
    courseSection: state => state.sections.courseSection,
    user: state => state.session.user,
  }),
  data: () => ({
    periods: {
      first: [],
      second: []
    },
    lessons: [],
    URL,
    token,
    lesson: [],
    loading: false
  }),
  methods: {
    ...mapActions({
      fetchCourseSection: 'sections/fetchCourseSections'
    }),
    getPercentNote(note) {
      return (note * this.lesson.evaluation_weighting_prc) / 20
    },
    getGeneralNote(note) {
      return (this.getPercentNote(note) * 20) / 100
    },
    mapNotesBy(absence, cut, lesson) {
      return (((this.lessons.filter(it => it.period_cut === cut)
        .find(it => it.id === lesson?.id) || {})
        .students || [])
        .find(it => (it.id === absence.student.id) && it.pivot.lesson_id === lesson.id) || {})?.pivot?.note ?? 'S/N'
    },
    getSemesterName() {
      switch (this.courseSection?.semester?.number) {
        case 1: return "Primer";
        case 2: return "Segundo";
        case 3: return "Tercer";
        case 4: return "Cuarto";
        case 5: return "Quinto";
        case 6: return "Sexto";
        case 7: return "Septimo";
        case 8: return "Octavo";
        case 9: return "Noveno";
        case 10: return "Décimo";
        case 11: return "Décimo primer";
        case 12: return "Décimo segundo";
        case 13: return "Décimo tercer";
        case 14: return "Décimo cuarto";
        case 15: return "Décimo quinto";
        default: this.courseSection?.semester?.number
      }
    }
  },
  watch: {
    // courseSection(val) {
    //   if (val) {
    //     this.periods = {
    //       first: val.lessons.filter(it => it.period_cut === 1),
    //       second: val.lessons.filter(it => it.period_cut === 2)
    //     }
    //     this.lessons = val.lessons
    //   }
    // }
  },
  mounted() {
    // this.fetchCourseSection({ ...this.$route.params, filters: { with: 'absences.student,absences.lesson,lessons.students,section,course,semester' } })
    this.loading = true
    this.$repository.lessons
      .show(this.$route.params.lesson_id, { with: 'students' })
      .then(({ data: response }) => {
        this.lesson = response
     })
      .finally(() => (this.loading = !this.loading ))
  }
}
</script>
